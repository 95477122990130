























































import {
  defineComponent,
  useContext,
  onMounted,
  computed
} from '@nuxtjs/composition-api'
import { SfOverlay } from '@storefront-ui/vue'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { useUiState, useConfig } from '@wemade-vsf/composables';
import { useSearch } from '@wemade-vsf/search';
import { useUser } from '@wemade-vsf/customer';
import CartNavigation from 'components/theme/Header/CartNavigation.vue';
import MobileNavigationBar from 'components/theme/Header/MobileNavigationBar.vue';
import TopNavigation from 'components/theme/Header/TopNavigation.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    CartNavigation,
    HeaderLogo,
    MegaMenu: () => import('components/megamenu/MegaMenu.vue'),
    MobileNavigationBar,
    SfOverlay,
    TopNavigation,
    SearchBar: () => import('components/search/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search/SearchResults.vue'),
    MegaMenuSidebar: () => import('components/megamenu/MegaMenuSidebar.vue'),
    TopBar: () => import('components/theme/Header/TopBar.vue')
  },
  props: {
    menuOrientation: {
      type: String,
      default: 'horizontal'
    }
  },
  setup () {
    const { $config } = useContext();
    const { 
      isMobileMenuOpen: isMobileMenu,
      toggleMobileMenu,
      hideSearch
    } = useUiState();


    const {
      isSearchOpen
    } = useSearch();
    
    const { isAuthenticated, load: loadUser, user } = useUser();
    const { config } = useConfig();
    const showWishlist = $config.wishlist?.enable ?? false;
    const showCart = computed(() => {
      if (!user.value) {
        return !(config.value?.storefront?.hide_cart_button ?? false)
      }
      return !user.value.hide_cart_button
    })
    const isSticky = config.value?.storefront?.sticky_header ?? false;

    const topBarConfig = computed(() => {
      if (config.value?.storefront?.top_bar_block_enabled && config.value?.storefront?.top_bar_block_identifier) {
        return {
          background: config.value.storefront.top_bar_block_background_color,
          identifier: config.value.storefront.top_bar_block_identifier
        }
      }
      return null
    })

    onMounted(async() => {
      if (isAuthenticated.value && !user.value) {
        await loadUser();
      }
    });

    return {
      isMobileMenu,
      isSearchOpen,
      showWishlist,
      showCart,
      isSticky,
      toggleMobileMenu,
      hideSearch,
      topBarConfig
    }
  }
})
